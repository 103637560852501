<template>
    <div class="random-cases-section" v-if="randomCases && randomCases.length">
        <div class="wrapper">
            <div class="section">
                <div class="slider-header">
                    <div class="left">
                        <div class="bottom">
                            <h2 class="slider-title">{{ $t(`Ultimate StormBills offers`) }}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="random-cases-slider" @click="handleClick">
                <slick ref="slick" :options="slickOptions" class="slider products" @swipe="handleSwipe">
                    <div v-for="(card, index) in randomCases" :key="index" class="item card" :data-slug="card.slug">
                        <div class="card-wrapper" :class="card.type">
                            <div class="card-content">
                                <div class="card-image-container">
                                    <img :src="card.image" :alt="card.name" class="card-image" />
                                </div>
                                <div class="card-footer">
                                    <h3 class="card-title">{{ card.name }}</h3>
                                    <p class="card-description">{{ card.description }}</p>
                                </div>
                                <div class="card-price">{{ card.price }} {{ currencySymbol }}</div>
                            </div>
                        </div>
                    </div>
                </slick>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Slick from 'vue-slick';

export default {
    name: 'RandomCasesSlider',
    components: { Slick },
    created() {
        this.fetchRandomCases();
    },
    mounted() {
        this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    data() {
        return {
            tooltipContent: `
          <h2 class="tooltip-title">Ultimate StormBills offers</h2>
          <p class="tooltip-description">
          Please be advised that items obtained from these cases are distributed on a purely random basis...
          </p>
        `,
            sliderIsClickble: true,
            slickOptions: {
                slidesToShow: this.calculateSlidesToShow(),
                slidesToScroll: this.calculateSlidesToScroll(),
                arrows: true,
                infinite: false,
                dots: false,
                lazyLoad: 'ondemand',
            },
            randomCases: []
        };
    },
    computed: {
        ...mapGetters('app', ['currencySymbol', 'currency']),
    },
    watch: {
        currency() {
            this.fetchRandomCases();
        }
    },
    methods: {
        handleResize() {
            this.slickOptions.slidesToShow = this.calculateSlidesToShow();
            this.slickOptions.slidesToScroll = this.calculateSlidesToScroll();
            this.$nextTick(() => {
                this.$refs.slick.reSlick();
            })
        },
        calculateSlidesToShow() {
            let containerWidth = innerWidth >= 1440 ? 1200 : innerWidth - 16;
            const cardWidth = 280;
            const gap = 24;
            return Math.floor((containerWidth + gap) / (cardWidth + gap));
        },
        calculateSlidesToScroll() {
            return this.calculateSlidesToShow();
        },
        handleSwipe() {
            this.sliderIsClickble = false;
            setTimeout(() => {
                this.sliderIsClickble = true;
            }, 300);
        },
        handleClick(event) {
            const card = event.target.closest('.card');
            if (card && this.sliderIsClickble) {
                const slug = card.dataset.slug;
                this.$router.push(`/coin-bundles/${slug}`);
            }
        },
        async fetchRandomCases() {
            try {
                const res = await this.$http.get(process.env.VUE_APP_API + 'coin-bundles');
                if (res.status === 200) {
                    this.randomCases = res.data.payload;
                }
            } catch (res) {
                if (res.response.status === 401) {
                    this.$parent.openSignInModal();
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.random-cases-section {
    margin-top: 100px;

    .slider-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
        line-height: 65px;
        margin-bottom: 40px;

        .left {
            .bottom {
                display: flex;
                gap: 11px;
            }

            .slider-title {
                font-size: calc(clamp(1rem, 0.1429rem + 4.2857vw, 4rem));
                color: #070707;
                font-weight: 700;
            }
        }
    }
}

.random-cases-slider {
    height: 353px;
}

.card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 280px;
    height: 353px;
    position: relative;
    transition: all 0.15s ease;
    margin: 0 12px;
    cursor: pointer;

    &:hover {
        opacity: 0.6;
    }
}

.card-wrapper .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card-price {
    max-width: 110px;
    width: 100%;
    height: 48px;
    background: #1b9b65;
    color: white;
    border-radius: 16px;
    z-index: 2;
    padding: 12px 5px;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-image-container {
    position: relative;
    width: 180px;
    height: 180px;
    pointer-events: none;
    margin-bottom: 12px;
}

.card-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
}

.card-image {
    position: relative;
    z-index: 1;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.card-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 4px;
    color: #070707;
    height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 160px;
    text-align: center;
}

.card-description {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    height: 18px;
    color: #070707;
    text-align: center;
    max-width: 160px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>