<template>
  <header class="header">
    <div class="wrapper flex">
      <div class="left">
        <router-link to="/" class="logo flex">
          <img src="./../images/logo.svg" class="img" />
          <div class="title">Promptstorms</div>
        </router-link>
      </div>
      <div class="right">
        <div class="nav">
          <div class="currency-select-container cart" v-if="curr && currOptions && currOptions.length > 1">
            <select class="select currency-select" v-model="curr">
              <option :value="item.id" v-for="item in currOptions" :key="item.id">
                {{ item.text }}
              </option>
            </select>
          </div>
          <LanguageSelectDropdown />
          <div class="nav__item">
            <router-link to="/" class="desc">{{ $t(`Home`) }}</router-link>
          </div>
          <div class="nav__item">
            <router-link to="/product-list" class="desc">{{ $t(`Prompts`) }}</router-link>
          </div>
          <div class="nav__item">
            <div @click="scrollToSection('howItWorks')" class="desc hover-link">{{ $t(`How it works?`) }}</div>
          </div>
          <div class="nav__item">
            <div @click="scrollToSection('weBelieve')" class="desc hover-link">{{ $t(`We belive`) }}</div>
          </div>
          <div class="nav__item">
            <router-link to="/contacts" class="desc">{{ $t(`Contacts`) }}</router-link>
          </div>
        </div>
        <router-link to="/profile/promt-history" class="desc link-container dropdown-open" v-if="isAuth">
          <img src="./../images/user.svg" class="img" />
          <span>{{ $t(`My account`) }}</span>
        </router-link>
        <div class="link-container" v-if="!isAuth">
          <div class="desc modal-open flex hover-link" @click="$emit('openSignInModal')">
            <img src="./../images/login.svg" class="img" />
            <span class="login">{{ $t(`Login`) }}</span>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LanguageSelectDropdown from '@/components/LanguageSelectDropdown.vue';

export default {
  name: 'Header',
  components: {
    LanguageSelectDropdown
  },
  computed: {
    ...mapGetters("app", ["currency", "allCurrencies"]),
    ...mapGetters('user', ['isAuth']),

    curr: {
      get() {
        return this.currency;
      },
      set(value) {
        this.changeCurrency(value);
      }
    },

    currOptions() {
      return this.allCurrencies;
    }
  },
  methods: {
    ...mapActions("app", ["changeCurrency"]),
    openSignInModal() {
      this.$emit('openSignInModal')
    },
    openSignUpModal() {
      this.$emit('openSignUpModal')
    },
    logout() {
      this.$emit('logout')
    },
    searchSubmit() {
      this.$emit('searchSubmit', this.search)
    },
    onEnter() {
      this.searchSubmit();
    },
    scrollToSection(section) {
      this.$emit('scrollToSection', section)
    },
  },
  mounted() {
  }
}
</script>
