<template>
	<div class="item-wrapper" v-if="item">
		<div class="top">
			<!-- <div v-if="tag" class="tag">
				<span>{{modifiedTag}}</span>
			</div> -->
			<div class="title" @click="goToSliderItem(item)">{{ item.title }}</div>
			<div class="desc" @click="goToSliderItem(item)" v-html="item.description"></div>
			<div class="buy">
				<button class="desc buy-desc hover-link" @click="getPack">
					<span>{{ $t(`Buy`) }}</span>
				</button>
				<div class="title">{{ item.price }}&nbsp;{{ currency }}</div>
			</div>
		</div>
		<div class="bottom">
			<div class="preview" @click="goToSliderItem(item)" v-if="lazy">
				<img :data-lazy="imgDomain + item.image_url" class="img" />
			</div>
			<div class="preview" @click="goToSliderItem(item)" v-else>
				<img :src="imgDomain + item.image_url" class="img" />
			</div>
		</div>
	</div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
	name: 'ProductCard',
	props: ['item', 'sliderIsClickble', 'tag', 'currency', 'models', 'activePack', 'lazy', 'isPack'],
	data: function () {
		return {
			imgDomain: ''
		}
	},
	watch: {
	},
	// computed: {
	// 	modifiedTag: function() {
	// 		if (this.isNumber(this.tag)) {
	// 			let array = this.models.find(item => item.id === this.tag);
	// 			let newtag = array['title']
	// 			return newtag;
	// 		} else {
	// 			return this.tag
	// 		}
	// 	}
	// },
	mounted() {
		this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
	},
	methods: {
		openReadyModal(item, product) {
			this.$emit('openReadyModal', item, product);
		},
		isNumber(variable) {
			return Number.isFinite(variable) && typeof variable === "number";
		},
		goToSliderItem(item) {
			let self = this;
			setTimeout(() => {
				if (self.sliderIsClickble == true) {
					self.$emit('goToProduct', item);
				}
			}, 200)
		},
		getPack() {
			if (this.isAuth) {
				if (this.isPack) {
					this.openReadyModal('pack', this.item);
				} else {
					this.openReadyModal('prompt', this.item);
				}
			} else {
				this.openSignInModal();
			}
		},
		openSignInModal() {
			this.$emit('openSignInModal');
		}
	},
	computed: {
		...mapGetters('user', ['isAuth'])
	}
}
</script>
