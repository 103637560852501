<template>
  <div class="menu">
    <router-link to="/" class="logo flex">
      <img src="./../images/logo.svg" class="img" />
      <div class="title">Promptstorms</div>
    </router-link>
    <div class="nav">
      <div class="nav__item">
        <router-link to="/" class="desc">{{ $t('Home') }}</router-link>
      </div>
      <div class="nav__item">
        <router-link to="/product-list" class="desc">{{ $t('Prompts') }}</router-link>
      </div>
      <div class="nav__item">
        <div @click="scrollToSection('howItWorks')" class="desc hover-link">{{ $t('How it works?') }}</div>
      </div>
      <div class="nav__item">
        <div @click="scrollToSection('weBelieve')" class="desc hover-link">{{ $t('We believe') }}</div>
      </div>
      <div class="nav__item">
        <router-link to="/contacts" class="desc">{{ $t('Contacts') }}</router-link>
      </div>
      <div class="nav__item">
        <LanguageSelectDropdown />
      </div>
      <div class="nav__item">
        <div class="currency-select-container cart" v-if="currencies.length > 1">
          <select class="select currency-select" v-model="selectedCurrency" @change="selectCurrency">
            <option :value="currency.id" v-for="currency in currencies" :key="currency.id">
              {{ currency.text }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import LanguageSelectDropdown from "@/components/LanguageSelectDropdown.vue";

export default {
  name: 'Menu',
  components: {
    LanguageSelectDropdown
  },
  computed: {
    ...mapState('app', ['currencies', 'currency']),
    selectedCurrency: {
      get() {
        return this.currency;
      },
      set(value) {
        this.changeCurrency(value);
      }
    }
  },
  methods: {
    ...mapActions('app', ['changeCurrency']),
    selectCurrency() {
      localStorage.setItem("currency", this.selectedCurrency); // Save selected currency to localStorage
    },
    scrollToSection(section) {
      this.$emit('scrollToSection', section);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .custom-lang-select.lang-select-container {
  padding: 0;

  .lang-dropdown-selected {
    font-weight: 400;
    font-size: 16px;
    line-height: normal;
    color: #63726A;
    gap: 20px;
    justify-content: flex-start;
  }

  .lang-dropdown-menu {
    left: -10px;
    background: #100F11;
    font-weight: 400;
    font-size: 16px;
    line-height: normal;
    color: #63726A;
  }

  .lang-dropdown-item {}
}
</style>
