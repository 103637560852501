<template>
  <main class="main profile-page page-inside" v-if="isAuth">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title">{{ $t(`My account`) }}</div>
          <div class="profile-top">
            <div class="profile-top-left">
              <img src="./../images/user-white.svg" class="img" />
              <div class="text">
                <div class="title small">{{ name }} {{ surname }}</div>
                <div class="desc">
                  <b>{{ $t(`Joined:`) }}</b>
                  {{ $parent.formatDate(joined) }}
                </div>
              </div>
            </div>
            <div class="profile-top-right">
              <img src="./../images/walletProfile.svg" class="img" />
              <div class="text">
                <div class="title small">{{ $t(`My balance`) }}</div>
                <div class="title"><span>{{ balance }} {{ $parent.internalCurrencyName }}</span></div>
              </div>
              <button class="button green" @click="$parent.openTopUpModal(0.0)">
                <span>{{ $t(`Top Up`) }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="section profile-section">
        <img src="./../images/believe-peace.svg" class="img peace" />
        <div class="wrapper">
          <div class="profile-form">
            <div class="profile-form-wrapper">
              <div class="wrapper">
                <div class="profile-nav-container">
                  <ul class="profile-nav pack-list">
                    <template v-for="(item, i) in profileNav">
                      <li :class="['pack-list__item', { 'active': item.page === activeProfilePage }]"
                        @click="goToProfilePage(item.page)" :key="i">
                        <div class="desc">{{ item.title }}</div>
                      </li>
                    </template>
                  </ul>
                  <li class="logout-item hover-link" @click="$parent.logout">
                    <img src="./../images/logout.svg" class="img" />
                    <div class="desc">{{ $t(`Log out`) }}</div>
                  </li>
                </div>
                <div class="content personal-info" v-if="activeProfilePage === 'contact-info'">
                  <div class="block">
                    <div class="block-top">
                      <div class="title small">{{ $t(`Contact Information`) }}</div>
                      <div class="fields">
                        <div class="input-container">
                          <div class="desc"><span class="red">*</span> {{ $t('First name') }}:</div>
                          <input type="text" :placeholder="$t('First name')" v-model="name" />
                          <transition name="fade">
                            <div v-if="errors.name && errors.name.length" class="error-message">
                              {{ errors.name[0] }}
                            </div>
                          </transition>
                        </div>
                        <div class="input-container">
                          <div class="desc"><span class="red">*</span> {{ $t('E-mail') }}:</div>
                          <input type="email" :placeholder="$t('E-mail')" v-model="email" />
                          <transition name="fade">
                            <div v-if="errors.email && errors.email.length" class="error-message">
                              {{ errors.email[0] }}
                            </div>
                          </transition>
                        </div>
                        <div class="input-container">
                          <div class="desc"><span class="red">*</span> {{ $t('Last name') }}:</div>
                          <input type="text" :placeholder="$t('Last name')" v-model="surname" />
                          <transition name="fade">
                            <div v-if="errors.surname && errors.surname.length" class="error-message">
                              {{ errors.surname[0] }}
                            </div>
                          </transition>
                        </div>
                        <div class="input-container" v-if="countryOptionsIso && countryOptionsIso.length">
                          <div class="desc"><span class="red">*</span> {{ $t('Phone') }}:</div>
                          <VuePhoneNumberInput v-model="phone" :only-countries="countryOptionsIso"
                            :default-country-code="getDefaultCountryIso" @update="phoneDataUpdate($event)" />
                          <transition name="fade">
                            <div v-if="errors.phone && errors.phone.length" class="error-message">
                              {{ errors.phone[0] }}
                            </div>
                          </transition>
                        </div>
                      </div>
                    </div>
                    <button class="button" @click="updateProfileData">
                      <span>{{ $t(`Save`) }}</span>
                    </button>
                    <transition name="fade">
                      <div class="desc red" v-if="profileUpdateErrorMessaage">{{ profileUpdateErrorMessaage }}</div>
                    </transition>
                    <transition name="fade">
                      <div class="desc green" v-if="profileUpdateSuccessMessaage">{{ profileUpdateSuccessMessaage }}
                      </div>
                    </transition>
                  </div>

                  <div class="block">
                    <div class="block-top">
                      <div class="title small">{{ $t(`Address Information`) }}</div>
                      <div class="fields">

                        <div class="input-container">
                          <div class="desc"><span class="red">*</span> {{ $t('Address') }}:</div>
                          <input type="text" :placeholder="$t('Address')" v-model="address" />
                          <transition name="fade">
                            <div v-if="errors.address && errors.address.length" class="error-message">
                              {{ errors.address[0] }}
                            </div>
                          </transition>
                        </div>

                        <div class="input-container">
                          <div class="desc"><span class="red">*</span> {{ $t('City') }}:</div>
                          <input type="text" :placeholder="$t('City')" v-model="city" />
                          <transition name="fade">
                            <div v-if="errors.city && errors.city.length" class="error-message">
                              {{ errors.city[0] }}
                            </div>
                          </transition>
                        </div>

                        <div class="input-container">
                          <div class="desc"><span class="red">*</span> {{ $t('Post Code') }}:</div>
                          <input type="text" :placeholder="$t('Post Code')" v-model="postCode" />
                          <transition name="fade">
                            <div v-if="errors.post_code && errors.post_code.length" class="error-message">
                              {{ errors.post_code[0] }}
                            </div>
                          </transition>
                        </div>

                        <div class="input-container">
                          <div class="desc"><span class="red">*</span> {{ $t('Country') }}:</div>
                          <select v-model="country"
                            :class="{ 'select-placeholder-text': country == '' || country == null }">
                            <option selected value="">{{ $t(`Country`) }}</option>
                            <option v-for="item in countryOptions" :value="item" :key="item.iso">{{ item.name }}
                            </option>
                          </select>
                          <transition name="fade">
                            <div v-if="errors.country && errors.country.length" class="error-message">
                              {{ errors.country[0] }}
                            </div>
                          </transition>
                        </div>

                      </div>
                    </div>
                    <button class="button" @click="updateProfileData">
                      <span>{{ $t(`Save`) }}</span>
                    </button>
                  </div>

                  <div class="block">
                    <div class="block-top">
                      <div class="title small">{{ $t(`Change Password`) }}</div>
                      <div class="fields">
                        <div class="input-container">
                          <div class="desc"><span class="red">*</span> {{ $t('Current password') }}:</div>
                          <input type="text" :placeholder="$t('Current password')" v-model="curPass" />
                        </div>
                        <div class="input-container long">
                          <div class="desc"><span class="red">*</span> {{ $t('New password') }}:</div>
                          <input type="text" :placeholder="$t('New password')" v-model="newPass" />
                        </div>
                        <div class="input-container long">
                          <div class="desc"><span class="red">*</span> {{ $t('Confirm password') }}:</div>
                          <input type="text" :placeholder="$t('Confirm password')" v-model="repeatPass" />
                        </div>
                        <div class="pass-rules">
                          <div class="desc bold">{{ $t('Password must contain:') }}</div>
                          <div class="desc">{{ $t(`At least 6 characters, 1 upper case letter (A-Z), 1 lower case letter
                            (a-z), 1 number (0-9)`) }}</div>
                        </div>
                      </div>
                    </div>
                    <button class="button" @click="updatePassword">
                      <span>{{ $t(`UPDATE`) }}</span>
                    </button>
                    <transition name="fade">
                      <div class="desc red" v-if="passUpdateErrorMessaage">{{ passUpdateErrorMessaage }}</div>
                    </transition>
                    <transition name="fade">
                      <div class="desc green" v-if="passUpdateSuccessMessaage">{{ passUpdateSuccessMessaage }}</div>
                    </transition>
                  </div>

                  <div class="block">
                    <div class="block-top">
                      <div class="title small">{{ $t(`Referral code`) }}</div>
                      <div class="fields">
                        <div class="input-container">
                          <div class="desc"><span class="red">*</span> {{ $t('Referral code') }}:</div>
                          <input type="text" :placeholder="$t('Referral code')" v-model="referralCode" />
                        </div>
                      </div>
                    </div>
                    <button class="button" @click="updateReferralCode">
                      <span>{{ $t(`UPDATE`) }}</span>
                    </button>
                  </div>

                </div>
                <div class="content profile-table" v-if="activeProfilePage === 'promt-history'">
                  <div class="profile-table-wrapper profile-form-wrapper">
                    <div class="title small">{{ $t(`My promptstorms`) }}</div>
                    <div class="table-container">
                      <table>
                        <thead>
                          <tr>
                            <td>{{ $t(`Title`) }}</td>
                            <td>{{ $t(`Prompt`) }}</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, i) in $parent.promtHistory" :key="i">
                            <td>{{ item.title }}</td>
                            <td v-html="item.prompt"></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="content profile-table" v-if="activeProfilePage === 'order-history'">
                  <div class="profile-table-wrapper profile-form-wrapper">
                    <div class="title small">{{ $t(`Order history`) }}</div>
                    <div class="table-container">
                      <table>
                        <thead>
                          <tr>
                            <td>{{ $t(`Date/time`) }}</td>
                            <td>{{ $t(`Title`) }}</td>
                            <td>{{ $t(`Status`) }}</td>
                            <td>{{ $t(`Total`) }}</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, i) in $parent.orderHistory" :key="i">
                            <td>{{ $parent.formatDate(item.created_at) }}</td>
                            <td>{{ item.title }}</td>
                            <td>
                              <div :class="['status', { 'green': item.status.toLowerCase() === 'success' }]">
                                {{ item.status }}
                              </div>
                            </td>
                            <td><b>{{ item.sum }}&nbsp;{{ item.currency }}</b></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="content profile-table" v-if="activeProfilePage === 'deposit-history'">
                  <div class="profile-table-wrapper profile-form-wrapper">
                    <div class="title small">{{ $t(`Deposit history`) }}</div>
                    <div class="table-container">
                      <table>
                        <thead>
                          <tr>
                            <td>{{ $t(`Date/time`) }}</td>
                            <td>{{ $t(`Status`) }}</td>
                            <td>{{ $t(`Total`) }}</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, i) in $parent.depositHistory" :key="i">
                            <td>{{ $parent.formatDate(item.created_at) }}</td>
                            <td>
                              <div :class="['status', { 'green': item.status.toLowerCase() === 'success' }]">
                                {{ item.status }}
                              </div>
                            </td>
                            <td><b>{{ item.sum }}&nbsp;{{ item.currency }}</b></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: 'Profile',
  props: ['currency'],
  components: {
  },
  data: function () {
    return {
      email: '',
      name: '',
      surname: '',
      phone: '',
      joined: '',
      balance: '',
      profileUpdateSuccessMessaage: '',
      profileUpdateErrorMessaage: '',
      curPass: '',
      newPass: '',
      repeatPass: '',
      passUpdateSuccessMessaage: '',
      passUpdateErrorMessaage: '',
      activeProfilePage: '',
      profileNav: [
        {
          page: 'promt-history',
          title: 'My promptstorms'
        },
        {
          page: 'contact-info',
          title: 'Contact Information'
        },
        {
          page: 'deposit-history',
          title: 'Deposit history'
        },
        {
          page: 'order-history',
          title: 'Order history'
        }
      ],
      phoneFull: "",
      countryOptions: [],
      countryOptionsIso: [],
      userCountryId: '',

      country: '',
      city: '',
      address: '',
      postCode: '',
      referralCode: '',
      errors: {}
    }
  },
  watch: {
    currency: function () {
      this.getProfile();
    },
  },
  mounted() {
    this.activeProfilePage = this.$route.params.page;
    Promise.all([this.getProfile(), this.getCountries()])
      .then(() => {
        this.setDefaultCountry();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          this.$parent.openSignInModal();
        } else {
          console.error(err);
        }
      });
  },
  methods: {
    getCountries() {
      return this.$http.get(process.env.VUE_APP_API + 'countries')
        .then((res) => {
          this.countryOptions = res.data.payload;
          this.countryOptionsIso = res.data.payload.map(obj => obj.iso);
        });
    },
    getProfile() {
      return this.$http.get(process.env.VUE_APP_API + 'user/profile')
        .then((res) => {
          if (res.status == 200) {
            this.email = res.data.email;
            this.name = res.data.name;
            this.surname = res.data.surname;
            this.phone = res.data.phone;
            this.phoneCountryCode = res.data.phone_country_code;
            this.address = res.data.address;
            this.postCode = res.data.post_code;
            this.city = res.data.city;
            this.userCountry = res.data.country;
            this.joined = res.data.created_at;
            this.balance = res.data.balance;
            this.referralCode = res.data.referral_code;
          }
        });
    },
    setDefaultCountry() {
      if (this.userCountry && this.countryOptions.length) {
        this.country = this.countryOptions.find(item => item.iso == this.userCountry.iso);
      }
    },
    phoneDataUpdate(e) {
      this.phoneFull = e.formattedNumber || e.phoneNumber;
    },
    updateProfileData() {
      let profileData = {
        "email": this.email,
        "name": this.name,
        "phone": this.phoneFull,
        "surname": this.surname,
        "address": this.address,
        'city': this.city,
        'post_code': this.postCode,
        "country_id": this.country ? this.country?.id : null
      }
      this.$http.put(process.env.VUE_APP_API + 'user/profile', profileData)
        .then((res) => {
          if (res.data.status == "OK") {
            this.errors = {}
            this.$toast.success('Profile successfully updated!');
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
          this.errors = res.response.data.errors;

          clearTimeout(this.timeoutId); // cancel previous timeout
          this.timeoutId = setTimeout(() => {
            // this.profileUpdateErrorMessaage = '';
            this.errors = {};
          }, 3000);
        })
    },
    updatePassword() {
      let passwordList = {
        "cur_password": this.curPass,
        "new_password": this.newPass,
        "repeat_password": this.repeatPass
      }
      this.$http.post(process.env.VUE_APP_API + 'user/change-password', passwordList)
        .then((res) => {
          if (res.data.status == "OK") {
            this.passUpdateSuccessMessaage = 'Success';
            this.curPass = '',
              this.newPass = '',
              this.repeatPass = '';
            setTimeout(() => {
              this.passUpdateSuccessMessaage = '';
            }, 3000);
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
          this.passUpdateErrorMessaage = res.response.data.message;
          setTimeout(() => {
            this.passUpdateErrorMessaage = '';
          }, 3000);
        })
    },
    updateReferralCode() {
      let referralData = {
        "referral_code": this.referralCode
      };
      this.$http.put(process.env.VUE_APP_API + 'user/referral-code', referralData)
        .then((res) => {
          if (res.data.status == "OK") {
            this.$toast.success('Referral code successfully updated!');
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
          this.$toast.error(res.response.data.message);
        });
    },
    goToProfilePage(page) {
      this.$router.push({ path: '/profile/' + page });
      this.activeProfilePage = page;
    }
  },
  computed: {
    ...mapGetters('user', ['isAuth']),
    getDefaultCountryIso() {
      return this.phoneCountryCode || this.countryOptionsIso[0];
    },
  }
}
</script>