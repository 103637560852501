import Vue from 'vue';
import Vuex from 'vuex';
import user from "@/store/modules/user";
import app from './modules/app';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        user,
        app
    }
})