<template>
  <div class="modal topup-modal">
    <div class="overlay" @click="$parent.closeTopUpModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closeTopUpModal" src="./../images/close.svg" />
      <div class="container">
        <div>
          <div class="form-wrapper">
            <div class="form-fields" v-if="amountStep">
              <div class="title">{{ $t(`Enter amount`) }}</div>
              <div class="form-fields-wrapper enter-amount">
                <label>
                  <div class="amount-wrapper">
                    <input type="number" :placeholder="$t('Amount')" v-model="internalAmount" />
                    <div class="buttons"
                      v-if="$parent.settings && $parent.settings.top_up_def_amounts && $parent.settings.top_up_def_amounts.length">
                      <div v-for="item in $parent.settings.top_up_def_amounts" :key="item" @click="internalAmount = item"
                        class="button">
                        <span class="amount">{{ item }}</span>
                        <img class="img" :src="$parent.internalCurrencyImage" alt="" />
                      </div>
                    </div>
                  </div>
                </label>

                <div class="disclaimer desc">{{ exchangeRateDisclaimer }}</div>

                <button @click="nextStep" class="button">
                  <span>{{ $t(`Next`) }}</span>
                </button>
              </div>
            </div>
            <div class="form-fields" v-if="!amountStep">
              <div class="title">{{ $t(`Enter contact information`) }}</div>
              <div class="form-fields-wrapper">
                <label>
                  <input type="text" :placeholder="$t('Name')" v-model="name" />
                  <div v-if="errors.name && errors.name.length" class="error-message">{{ errors.name[0] }}</div>
                </label>
                <label>
                  <input type="text" :placeholder="$t('Surname')" v-model="surname" />
                  <div v-if="errors.surname && errors.surname.length" class="error-message">{{ errors.surname[0]
                    }}</div>
                </label>
                <label>
                  <input type="email" :placeholder="$t('E-mail address')" v-model="email" />
                </label>
                <label>
                  <VuePhoneNumberInput v-model="phone" :only-countries="countryOptionsIso"
                    :default-country-code="getDefaultCountryIso" @update="phoneDataUpdate($event)" />
                  <div v-if="errors.phone && errors.phone.length" class="error-message">{{ errors.phone[0] }}
                  </div>
                </label>
                <label>
                  <select v-model="country" :class="{ 'select-placeholder-text': country == '' || country == null }">
                    <option selected value="">{{ $t(`Country`) }}</option>
                    <option v-for="item in countryOptions" :value="item" :key="item.iso">{{ item.name }}
                    </option>
                  </select>
                  <div v-if="errors.country && errors.country.length" class="error-message">{{ errors.country[0]
                    }}</div>
                </label>
                <label>
                  <input type="text" :placeholder="$t('City')" v-model="city" />
                  <div v-if="errors.city && errors.city.length" class="error-message">{{ errors.city[0] }}</div>
                </label>
                <label>
                  <input type="text" :placeholder="$t('Address')" v-model="address" />
                  <div v-if="errors.address && errors.address.length" class="error-message">{{ errors.address[0]
                    }}</div>
                </label>
                <label>
                  <input type="text" :placeholder="$t('Post Code')" v-model="postCode" />
                  <div v-if="errors.postCode && errors.postCode.length" class="error-message">{{
      errors.postCode[0] }}</div>
                </label>
                <div class="cta-container">
                  <div class="chekbox-container">
                    <label class="chekbox-label">
                      <div class="chekbox-label-wrapper">
                        <input type="checkbox" name="terms" v-model="terms" />
                        <div class="checkbox"></div>
                        <span class="title">{{ $t(`I agree with`) }}</span>
                        <a @click="goToPrivacy" class="title"> {{ $t(`privacy policy`) }}</a>
                        <span class="title"> {{ $t(`and`) }} </span>
                        <a @click="goToTerms" class="title">{{ $t(`terms and conditions`) }}</a>
                      </div>
                    </label>
                  </div>
                  <div class="total">
                    <div class="title price">Top Up: {{ realAmount }} {{ $parent.currency }}</div>
                  </div>
                  <button :class="['button', { 'disabled': !requiredFieldsAreFilled }]" @click="submit">
                    <span>{{ $t(`Top up`) }}</span>
                  </button>
                </div>
                <div v-if="errors.amount && errors.amount.length" class="error-message">{{ errors.amount[0] }}
                </div>
                <div v-if="errors.currency && errors.currency.length" class="error-message">
                  {{ errors.currency[0] }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopUpModal',
  props: {
    topUpAmount: {
      type: Number,
      required: true,
    }
  },
  components: {
  },
  data: function () {
    return {
      realAmount: null,
      realCurrency: '',
      internalAmount: 0,
      amount: '',
      amountStep: true,
      email: '',
      name: '',
      surname: '',
      phone: '',
      country: '',
      countryId: '',
      city: '',
      address: '',
      postCode: '',
      terms: false,
      countryOptions: [],
      countryOptionsId: [],
      phoneFull: "",
      countryCode: '',
      countryCodeList: [],
      profilePhone: '',
      countryOptionsIso: [],

      errors: {
        name: '',
        surname: '',
        phone: '',
        amount: '',
        country: '',
        city: '',
        address: '',
        postCode: ''
      }
    }
  },
  mounted() {
    if (this.topUpAmount && this.topUpAmount > 0) {
      this.internalAmount = this.topUpAmount;
      this.realAmount = this.topUpAmount;
      this.amountStep = false;
    }

    Promise.all([this.getProfile(), this.getCountries()])
      .then(() => {
        this.setDefaultCountry();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          this.$parent.openSignInModal();
        } else {
          console.error(err);
        }
      });
  },
  computed: {
    exchangeRateDisclaimer() {
        return `1 ${this.$parent.internalCurrencyName} = ${this.$parent.exchangeRate} ${this.$parent.currency}`;
    },
    getDefaultCountryIso() {
      return this.phoneCountryCode || this.countryOptionsIso[0];
    },
    requiredFieldsAreFilled() {
      let fieldsAreFilled;
      if (
        this.name && this.surname && this.phone &&
        this.realAmount && this.country &&
        this.city && this.address
        && this.postCode && this.terms
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  methods: {
    calculateRealAmount() {
      if (this.internalAmount && this.internalAmount > 0) {
        this.$http.post(process.env.VUE_APP_API + 'calculate-top-up', {
          internal_amount: this.internalAmount,
          user_currency_code: this.currency,
        })
          .then((res) => {
            if (res.data.status === 'OK') {
              this.realAmount = res.data.payload.amount_to_pay;
              this.realCurrency = res.data.payload.currency;
              this.amountStep = false;
            } else {
              this.errors = res.data.errors;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    getCountries() {
      return this.$http.get(process.env.VUE_APP_API + 'countries')
        .then((res) => {
          this.countryOptions = res.data.payload;
          this.countryOptionsIso = res.data.payload.map(obj => obj.iso);
        });
    },
    getProfile() {
      return this.$http.get(process.env.VUE_APP_API + 'user/profile')
        .then((res) => {
          if (res.status == 200) {
            this.email = res.data.email;
            this.name = res.data.name;
            this.surname = res.data.surname;
            this.phone = res.data.phone;
            this.phoneCountryCode = res.data.phone_country_code;
            this.address = res.data.address;
            this.postCode = res.data.post_code;
            this.city = res.data.city;
            this.userCountry = res.data.country;
            this.joined = res.data.created_at;
            this.balance = res.data.balance;
          }
        });
    },
    setDefaultCountry() {
      if (this.userCountry && this.countryOptions.length) {
        this.country = this.countryOptions.find(item => item.iso == this.userCountry.iso);
      }
    },
    phoneDataUpdate(e) {
      this.phoneFull = e.formattedNumber || e.phoneNumber;
    },
    submit() {
      if (this.requiredFieldsAreFilled) {
        let data = {
          "name": this.name,
          "surname": this.surname,
          "phone": this.phoneFull,
          "amount": this.realAmount,
          "country": this.country.id,
          "city": this.city,
          "address": this.address,
          "postCode": this.postCode
        }

        this.$http.post(process.env.VUE_APP_API + 'deposit/validate', data)
          .then((res) => {
            if (res.data.status === 'OK') {
              this.$parent.closeTopUpModal();
              this.$emit('openOrderModal', {
                orderData: data,
                paymentMethods: res.data.payload
              });

            } else {
              this.errors = res.data.errors;
            }
          })
          .catch((err) => {
            if (err.response && err.response.data && err.response.data.errors) {
              this.errors = err.response.data.errors;
            } else {
              console.error(err);
            }
          });
      }
    },
    nextStep() {
      if (this.internalAmount && this.internalAmount > 0) {
        if (this.topUpAmount && this.topUpAmount > 0) {
          this.realAmount = this.topUpAmount;
          this.amountStep = false;
        } else {
          this.calculateRealAmount();
        }
      }
    },
    goToTerms() {
      this.$parent.closeTopUpModal();
      this.$parent.goToPage('terms');
    },
    goToPrivacy() {
      this.$parent.closeTopUpModal();
      this.$parent.goToPage('privacy');
    }
  }
}
</script>