<template>
  <main class="main page-inside text-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
        <div class="title">{{ $t(`Contacts`) }}</div>
        </div>
      </div>
      <div class="section contact-section">
        <img src="./../images/believe-peace.svg" class="img peace"/>
        <div class="title">{{ $t(`Have a question?`) }}</div>
        <div class="form-fields-wrapper">
          <label>
            <input type="text" :placeholder="$t('Name')" v-model="name"/>
          </label>
          <label>
            <input type="text" :placeholder="$t('Surname')" v-model="surname"/>
          </label>
          <label>
            <input type="email" :placeholder="$t('E-mail address')" v-model="email"/>
          </label>
          <label v-if="countryOptions && countryOptions.length">
            <VuePhoneNumberInput v-model="phone" :only-countries="countryOptions" :default-country-code="countryOptions[0]" @update="phoneDataUpdate($event)"/>
          </label>
          <label class="full">
            <input type="text" :placeholder="$t('Topic')" v-model="topic"/>
          </label>
          <label class="full">
            <textarea :placeholder="$t('Message')" v-model="message"></textarea>
          </label>
          <button @click="contactSubmit" class="button">
            <span>{{ $t(`Send`) }}</span>
          </button>
          <transition name="fade">
            <div v-if="error" class="error-desc desc red">{{error}}</div>
          </transition>
          <transition name="fade">
            <div v-if="successMessage" class="error-desc desc green">{{successMessage}}</div>
          </transition>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  name: 'Contaacts',
  props: [],
  components: {
  },
  data: function() {
    return {
      successMessage: '',
      error: '',
      name: '',
      surname: '',
      email: '',
      phone: '',
      topic: '',
      message: '',
      countryOptions: [],
      phoneFull: ''
    }
  },
  mounted() {
    this.getCountries();
  },
  methods: {
    getCountries() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
      .then((res) => {
          this.countryOptions = res.data.payload.map(obj => obj.iso);
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    phoneDataUpdate(e) {
      this.phoneFull = e.formatInternational;
    },
    contactSubmit() {
      let data = {
        "name": this.name,
        "surname": this.surname,
        "email": this.email,
        "phone": this.phoneFull,
        "topic": this.topic,
        "message": this.message
      }
      let self = this;
      this.$http.post(process.env.VUE_APP_API + 'contact-us', data)
      .then((res) => {
        if (res.data.status == "OK") {
          self.error = '';
          self.successMessage = 'Thank you!'
          setTimeout(function() {
            self.successMessage = '';
          }, 2000)
        } else {
          this.error = res.data.message
        }
      })
      .catch((res) => {
        this.error = res.response.data.message;
      })
    },
  }
}
</script>