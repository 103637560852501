import axios from "@/axios";
import i18n from "@/i18n";

const state = {
  isLoading: false,
  languages: [],
  currentLanguage: localStorage.getItem("language") || "",
  currencies: [],
  currency: localStorage.getItem("currency"),
};

const getters = {
  currentLanguage: (state) => state.currentLanguage,
  currentLanguageName: ({ currentLanguage, languages }) => {
    if (currentLanguage) {
      const lang = languages.find((lang) => lang.id == currentLanguage);

      if (lang) {
        return lang.name;
      }
    }

    return "";
  },
  currentLanguageCode: ({ currentLanguage, languages }) => {
    if (currentLanguage) {
      const lang = languages.find((lang) => lang.id == currentLanguage);
      if (lang) {
        return lang.code;
      }
    }

    return "";
  },
  allLanguages: (state) => state.languages,
  allCurrencies: (state) => state.currencies,
  currency: (state) => state.currency,
  currencySymbol: ({ currencies, currency }) => {
    if (currency) {
      const curr = currencies.find(({ id }) => currency === id);

      if (curr) {
        return curr.symbol;
      }
    }

    return "";
  },
};

const actions = {
  fetchLanguages: async ({ commit, dispatch, state }) => {
    try {
      commit("setLoading", true);
      const {
        data: { status, payload },
      } = await axios.get(process.env.VUE_APP_API + "languages");
      if (status === "OK") {
        commit("setLanguages", payload);
        if (payload.length === 0) {
          commit("setCurrentLanguage", "");
          return;
        }

        // Find the default language option
        const defaultLanguage = payload.find((lang) => lang.default === 1);

        // Check if the current language is valid
        const isValidLanguage = payload.some(
          (lang) => lang.id == state.currentLanguage
        );
        if (!isValidLanguage) {
          // If the current language is not valid, use the default language
          await dispatch(
            "changeLocale",
            defaultLanguage ? defaultLanguage.id : payload[0].id
          );
        } else {
          await dispatch("changeLocale", state.currentLanguage);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      commit("setLoading", false);
    }
  },
  changeLocale: async ({ commit, getters }, localeId) => {
    if (localeId) {
      commit("setCurrentLanguage", localeId);

      try {
        const { data } = await axios.get("/localizations", {
          params: {
            lang_id: localeId,
          },
        });

        if (data) {
          let translations = {};

          if (Array.isArray(data) && data.length) {
            data.forEach(
              (translation) =>
                (translations[Object.keys(translation)[0]] =
                  Object.values(translation)[0])
            );
          } else if (typeof data === "object") {
            translations = data;
          }

          const { currentLanguageCode } = getters;

          i18n.setLocaleMessage(currentLanguageCode, translations);
          i18n.locale = currentLanguageCode;
        }
      } catch (e) {
        console.log(e);
      }
    }
  },
  fetchCurrencies: async ({ commit }) => {
    try {
      commit("setLoading", true);
      const {
        data: { status, payload },
      } = await axios.get(process.env.VUE_APP_API + "currencies");
      if (status === "OK") {
        const currencies = payload.map(({ code: text, symbol }) => ({
          text,
          id: text,
          symbol,
        }));
        commit("setCurrencies", currencies);

        if (localStorage.getItem("currency")) {
          commit("setCurrency", localStorage.getItem("currency"));
        } else {
          commit("setCurrency", currencies[0].text);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      commit("setLoading", false);
    }
  },
  changeCurrency: async ({ commit, state }, selectedCurrency) => {
    // If the selected currency is valid, update it
    const currencyExists = state.currencies.some(
      (currency) => currency.id === selectedCurrency
    );
    if (currencyExists) {
      commit("setCurrency", selectedCurrency);
    } else {
      console.warn("Selected currency is not available");
    }
  },
};

const mutations = {
  setLoading: (state, loading) => (state.isLoading = loading),
  setLanguages: (state, languages) => (state.languages = languages),
  setCurrencies: (state, currencies) => (state.currencies = currencies),
  setCurrency: (state, currency) => {
    localStorage.setItem("currency", currency);
    state.currency = currency;
  },
  setCurrentLanguage: (state, language) => {
    state.currentLanguage = language;
    if (language) {
      localStorage.setItem("language", language);
    } else {
      localStorage.removeItem("language");
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
