<template>
    <main class="main coin-bundle-page page-inside">
        <div class="main-wrapper">
            <section class="section hero-section">
                <div class="wrapper">
                    <router-link to="/" class="link hover-link desc">
                        <img src="./../images/back.svg" class="img" />
                        <span>{{ $t('Back to home') }}</span>
                    </router-link>
                </div>
            </section>
            <section class="section coin-bundle-section">
                <div class="wrapper">
                    <transition name="fade">
                        <div class="coin-bundle-preview" v-if="coinBundle">
                            <div class="preview-container">
                                <div class="preview-wrapper">
                                    <div class="preview">
                                        <img :src="coinBundle.image" class="img" :alt="coinBundle.name" />
                                    </div>
                                </div>
                            </div>
                            <div class="text">
                                <div class="text-wrapper">
                                    <div class="title">{{ coinBundle.name }}</div>
                                    <div class="desc description">
                                        <b>{{ $t('Description:') }}</b>
                                        <div class="desc" v-html="coinBundle.content_desc"></div>
                                    </div>
                                    <div class="row-price">
                                        <div class="desc">
                                            <b>{{ $t('Price:') }}</b>
                                        </div>
                                        <div class="title">{{ coinBundle.price }} <span class="currency">{{ currency
                                                }}</span></div>
                                        <button class="button green" @click="openTopUpModal">
                                            <span>{{ $t('Buy Now') }}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </section>
        </div>
    </main>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: 'CoinBundlePage',
    data() {
        return {
            coinBundle: null,
        };
    },
    computed: {
        ...mapGetters('app', ['currency']),
    },
    created() {
        this.fetchCoinBundle();
    },
    watch: {
        currency() {
            this.fetchCoinBundle();
        }
    },
    methods: {
        fetchCoinBundle() {
            const slug = this.$route.params.slug;
            this.$http.get(`${process.env.VUE_APP_API}coin-bundles/${slug}?currency=${this.currency}`)
                .then((res) => {
                    if (res.status === 200) {
                        this.coinBundle = res.data.payload;
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        openTopUpModal() {
            if (this.$parent.isAuth) {
                if (this.coinBundle && this.coinBundle.price) {
                    this.$emit('openTopUpModal', parseFloat(this.coinBundle.price));
                }
            } else {
              this.$parent.openSignInModal();
            }
        }
    }
};
</script>

<style scoped lang="scss">
.coin-bundle-page {
    .coin-bundle-preview {
        display: flex;
        width: 100%;
        margin: 0 auto;
        padding: 32px 0;

        .preview-container {
            width: 40%;
        }

        .preview-wrapper {
            display: flex;
            align-items: center;

            .title {
                width: 55%;
                text-align: left;
                font-size: 24px;
                line-height: 34px;
                font-weight: 600;
                padding-left: 50px;
                color: #fff;
            }
        }

        .preview {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0;

            img {
                width: 100%;
            }
        }

        .text {
            width: 60%;
            padding-left: 40px;
            padding-top: 12px;
            display: flex;
            justify-content: center;
        }

        .text-wrapper {
            width: 100%;
        }

        .title {
            text-align: left;
        }

        .tag {
            margin-top: 24px;
            position: relative;

            .desc+.desc {
                margin-top: 10px;
            }
        }

        .feature-list {
            display: flex;
            flex-wrap: wrap;
            margin: 30px 0 0;
            gap: 16px 48px;
        }

        .description {
            text-align: left;
            margin-top: 24px;

            .desc+.desc {
                margin-top: 10px;
            }
        }

        .desc span,
        .desc {
            background: transparent !important;
            color: #02150C;
        }

        .feature-list__item+.feature-list__item {
            margin-top: 0;
        }

        .feature-list__item {
            color: #8D8D8D;
            display: flex;
            align-items: center;

            img {
                margin-left: 10px;
            }
        }

        .row {
            margin-top: 0;
            display: flex;
            align-items: center;

            +.row {
                margin-top: 24px;
            }
        }

        .row-price {
            margin-top: 24px;

            .title {
                font-size: 24px;
                line-height: normal;
                margin-top: 6px;
            }

            .button {
                margin-top: 18px;
                display: table;
            }
        }

        .spec-text {
            text-align: right;
            margin-top: 12px;

            .desc {
                color: #63726A;
                font-size: 12px;
                text-align: left;

                +.desc {
                    margin-top: 28px;
                }
            }
        }
    }

    .hero-section {
        padding-top: 158px;
        padding-left: 16px;
        padding-right: 16px;
        text-align: left;
        min-height: 244px;
    }

    .hover-link {
        color: #fff;
        display: inline-flex;
        gap: 24px;
        align-items: center;
    }

    .products {
        margin-top: 48px;
    }
}
</style>
