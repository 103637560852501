<template>
  <div class="modal sign-modal">
    <div class="overlay" @click="$emit('closeSignUpModal')"></div>
    <div class="wrapper">
      <img class="close" @click="$emit('closeSignUpModal')" src="./../images/close.svg" />
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <div class='form-fields register-content'>
              <div class="form-fields-wrapper">
                <div class="title">{{ $t(`Create an account`) }}</div>
                <label>
                  <!-- <div class="desc">{{ $t(`*First name`) }}</div> -->
                  <input type="text" :placeholder="'*' + $t('First name')" v-model="name" @keydown="allowOnlyLetters" />
                </label>
                <label>
                  <!-- <div class="desc">{{ $t(`*Last Name`) }}</div> -->
                  <input type="text" :placeholder="'*' + $t('Last Name')" v-model="surname"
                    @keydown="allowOnlyLetters" />
                </label>
                <label>
                  <!-- <div class="desc">{{ $t(`*Email`) }}</div> -->
                  <input type="email" :placeholder="'*' + $t('Email')" v-model="email" />
                </label>
                <label v-if="countryOptionsIso && countryOptionsIso.length">
                  <!-- <div class="desc">{{ $t(`*Phone`) }}</div> -->
                  <VuePhoneNumberInput v-model="phone" :only-countries="countryOptionsIso"
                    :default-country-code="getDefaultCountryIso()" @update="phoneDataUpdate($event)"
                    :translations="{ phoneNumberLabel: '*' + $t(`Phone number`) }" />
                </label>
                <label>
                  <!-- <div class="desc">{{ $t(`*Password`) }}</div> -->
                  <input type="password" :placeholder="'*' + $t('Password')" v-model="pass" />
                </label>
                <label>
                  <!-- <div class="desc">{{ $t(`*Confirm Password`) }}</div> -->
                  <input type="password" :placeholder="'*' + $t('Confirm Password')" v-model="passConfirm" />
                </label>
                
                <label>
                  <input type="text" :placeholder="$t('Address')" v-model="address" />
                </label>

                <label>
                  <input type="text" :placeholder="$t('City')" v-model="city" />
                </label>

                <label>
                  <input type="text" :placeholder="$t('Post Code')" v-model="postCode" />
                </label>

                <label>
                  <select v-model="country" :class="{ 'select-placeholder-text': country == '' || country == null }">
                    <option selected value="">{{ $t(`Country`) }}</option>
                    <option v-for="item in countryOptions" :value="item" :key="item.iso">{{ item.name }}
                    </option>
                  </select>
                </label>

                <label>
                  <input type="text" :placeholder="$t('Referral code')" v-model="referralCode" />
                </label>

                <div class="cta-container">
                  <div class="chekbox-container">
                    <label class="chekbox-label">
                      <div class="chekbox-label-wrapper">
                        <input type="checkbox" name="terms" v-model="terms" />
                        <div class="checkbox"></div>
                        <span class="title">{{ $t(`I agree with`) }}</span>
                        <a @click="$parent.goToPage('privacy')" class="title"> {{ $t(`privacy policy`) }} </a>
                        <span class="title">{{ $t(`and`) }}</span>
                        <a @click="$parent.goToPage('terms')" class="title"> {{ $t(`terms and conditions`) }}</a>
                      </div>
                    </label>
                  </div>
                  <button :class="['button', { 'disabled': !requiredRegisterFieldsAreFilled }]" @click="submitRegister">
                    <span>{{ $t(`SIGN UP`) }}</span>
                  </button>
                </div>
                <transition name="slide">
                  <div v-if="$parent.error" class="error-desc desc red">{{ $parent.error }}</div>
                </transition>
                <div class="modal-bottom">
                  <div class="desc">{{ $t(`Have account?`) }}</div>
                  <div class="link switch-register" @click="$emit('openSignInModal')">{{ $t(`Login`) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignUpModal',
  props: [],
  components: {
  },
  data: function () {
    return {
      email: '',
      pass: '',
      name: '',
      surname: '',
      phone: '',
      passConfirm: '',
      terms: false,
      phoneFull: "",
      countryOptionsIso: [],
      countryOptions: [],
      country: '',
      countryId: '',
      city: '',
      address: '',
      postCode: '',
      referralCode: '',
    }
  },
  mounted() {
    this.getCountries();
  },
  computed: {
    requiredRegisterFieldsAreFilled() {
      let fieldsAreFilled;
      if (
        this.name && this.surname && this.phone &&
        this.email && this.pass && this.passConfirm
        && this.terms
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  methods: {
    getDefaultCountryIso() {
      return this.phoneCountryCode || this.countryOptionsIso[0];
    },
    getCountries() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
        .then((res) => {
          this.countryOptions = res.data.payload;
          this.countryOptionsIso = res.data.payload.map(obj => obj.iso);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        })
    },
    phoneDataUpdate(e) {
      this.phoneFull = e.formattedNumber || e.phoneNumber;
    },
    allowOnlyLetters(e) {
      const allowedCharacters = /^[^\d]+$/;
      if (!allowedCharacters.test(e.key)) {
        e.preventDefault();
      }
    },
    closeSignUpModal() {
      this.$emit('closeSignInModal')
    },
    openSignInModal() {
      this.$emit('openSignInModal')
    },
    submitRegister() {
      let regData = {
        "name": this.name,
        "surname": this.surname,
        "email": this.email,
        "phone": this.phoneFull,
        "password": this.pass,
        "passConfirm": this.passConfirm,
        "address": this.address,
        "post_code": this.postCode,
        "city": this.city,
        "country_id": this.country.id,
        "referral_code": this.referralCode
      }
      this.$emit('registration', regData)
    }
  }
}
</script>