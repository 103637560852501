<template>
  <div class="modal order-modal">
    <div class="overlay" @click="$parent.closeOrderModal"></div>
    <div class="wrapper">
      <div class="container">
        <img src="./../images/close.svg" @click="$parent.closeOrderModal" class="close" />
        <div class="button-list" v-if="$parent.availablePaymentMethods && $parent.availablePaymentMethods.length">
          <button v-for="(item, i) in $parent.availablePaymentMethods" class="img-btn" @click="submit(item.code)" :key="i">
            <div class="logo-wrapper">
              <img :src="item.image" class="img" />
            </div>
            {{ item.title }}
          </button>
        </div>
        <div v-else class="desc">{{ $t('No payment methods available') }}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OrderModal',
  props: ['orderData'],
  data: function () {
    return {

    }
  },
  methods: {
    submit(type) {
      this.$emit('orderSubmit', this.orderData, type)
    },
  },
  mounted() {
  }
}
</script>
