<template>
  <div class="verification-notice">
    <h1>{{ $t(`Email Already Verified`) }}</h1>
    <p class="email-desc">{{ $t(`Your email has already been verified. No further action is required.`) }}</p>
  </div>
</template>
  
<script>
export default {
  name: 'EmailAlreadyVerified',
  // ...
}
</script>
  
<style scoped>
.verification-notice {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  min-height: 80vh;
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  padding: 20px;
}

.verification-notice > h1 {
  font-family: 'IBM Plex Sans KR', sans-serif;
}

.email-desc {
  line-height: 20px;
  text-align: center;
  font-family: 'IBM Plex Sans KR', sans-serif;
}
</style>