import axios from "axios";
import router from "./router.js";
import store from "@/store";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API,
  withCredentials: true,
  timeout: 5000, // set the default timeout for requests to 5 seconds
});

// Request Interceptor
instance.interceptors.request.use(
  (config) => {
    let currency = localStorage.getItem('currency');
    if (!currency) {
        currency = 'EUR'
    }
    // const authToken = localStorage.getItem("authToken");

    // if (authToken) {
    //   config.headers["Authorization"] = `Bearer ${authToken}`;
    // }

    const lang_id = store.getters["app/currentLanguage"];

    const excludeUrls = [""];
    if (!excludeUrls.includes(config.url)) {
      if (config.method === "get") {
        config.params = {
          ...config.params,
          currency
        };
      } else if (
        ["post", "put", "patch"].includes(config.method.toLowerCase())
      ) {
        config.data = {
          ...config.data,
          lang_id,
          currency
        };
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptor
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (!error.response) {
      // Network error
      return Promise.reject(
        new Error("Network error, please try again later.")
      );
    }

    const { status } = error.response;
    if (status === 401) {
      await store.dispatch("user/changeIsAuth", false);
      router.push({ name: "Home" });
    } else if (status === 403) {
      await store.dispatch("user/changeEmailVerified", false);
      router.push({ name: "EmailVerificationNotice" });
    }

    return Promise.reject(error);
  }
);

export default instance;
