import axios from "axios";

export default {
    namespaced: true,
    state: {
        profile: {},
        isAuth: JSON.parse(localStorage.getItem("isAuthenticated") || "false"),
        emailVerified: JSON.parse(localStorage.getItem("emailVerified") || "false")
    },
    getters: {
        profile: state => state.profile,
        isAuth: state => state.isAuth,
        emailVerified: ({emailVerified}) => emailVerified,
        isVerified: ({profile}) => profile.status === 'approved',
        isNotVerified: ({profile}) => profile.status === 'denied',
    },
    actions: {
        async fetchProfile({commit}) {
            try {
                const {data} = await axios.get(process.env.VUE_APP_API + 'user/profile');
                commit('SET_PROFILE', data);
                return data;
            } catch (e) {
                console.log(e);
            }

            return null;
        },
        async initializeEnrollment() {
            try {
                const {data} = await axios.get(process.env.VUE_APP_API + 'finchecker/enrolment');
                return data.payload
            } catch (e) {
                console.log(e);
            }
        },
        // eslint-disable-next-line no-empty-pattern
        async checkEnrollmentStatus({}, sessionId) {
            try {
                const {data} = await axios.get(process.env.VUE_APP_API + `finchecker/check/${sessionId}`);
                return data;
            } catch (e) {
                console.log(e);
            }
        },
        changeIsAuth({ commit }, payload) {
            commit('SET_IS_AUTH', payload); // Commit the mutation with payload
        },
        changeEmailVerified({ commit }, payload) {
            commit('SET_EMAIL_VERIFIED', payload); // Commit the mutation with payload
        },
    },
    mutations: {
        SET_PROFILE(state, profile) {
            state.profile = profile;
        },
        SET_IS_AUTH(state, isAuth) {
            state.isAuth = isAuth
            localStorage.setItem("isAuthenticated", isAuth.toString());
        },
        SET_EMAIL_VERIFIED(state, emailVerified) {
            state.emailVerified = emailVerified
            localStorage.setItem("emailVerified", emailVerified.toString());
        },
        SET_PROFILE_STATUS(state, status) {
            state.profile = {
                ...state.profile,
                status
            }
        }
    }
}